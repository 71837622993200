import {defineStore} from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: useSupabaseUser(),
    userSettings: null,
    isLoading: false,
    isNewUser: false
  }),
  getters: {
    isAuthenticated: state => !!state.user
  },
  actions: {
    initializeAuth() {
      return new Promise(resolve => {
        const user = useSupabaseUser();
        resolve();
      });
    },
    async registerWithEmail(email) {
      this.loading = true;
      const supabase = useSupabaseClient();
      const {data, error} = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          emailRedirectTo:
            process.env.NODE_ENV !== 'production'
              ? 'http://localhost:3000/app/confirm'
              : 'https://www.paperarchive.io/app/confirm'
        }
      });
      if (error) throw error;
      if (!(!data.user && !data.session)) throw new Error('Registration failed. Please try again.');
      this.loading = false;
      return data;
    },
    async registerWithGoogle() {
      console.log('Register with Google flow initiated');
      this.loading = true;
      const supabase = useSupabaseClient();

      const {data, error} = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo:
            process.env.NODE_ENV !== 'production'
              ? 'http://localhost:3000/app/confirm'
              : 'https://www.paperarchive.io/app/confirm'
        }
      });
      if (error) throw error;
      this.loading = false;
      return data;
    },
    async loginWithMagicLink(email) {
      this.loading = true;
      const supabase = useSupabaseClient();
      const {data, error} = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          emailRedirectTo:
            process.env.NODE_ENV !== 'production'
              ? 'http://localhost:3000/app/confirm'
              : 'https://www.paperarchive.io/app/confirm'
        }
      });
      if (error) throw error;
      this.loading = false;
      return data;
    },
    async loginWithEmailAndPassword(email, password) {
      this.loading = true;
      const supabase = useSupabaseClient();
      const {data, error} = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
        options: {
          emailRedirectTo:
            process.env.NODE_ENV !== 'production'
              ? 'http://localhost:3000/app/confirm'
              : 'https://www.paperarchive.io/app/confirm'
        }
      });
      if (error) throw error;
      if (!data.user) throw new Error('No user returned from Supabase');
      this.loading = false;
      return {};
    },
    async fetchUserSettings() {
      const supabase = useSupabaseClient();
      const {data, error} = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', this.user.id)
        .single();
      if (error) throw error;
      this.userSettings = data;
    },
    async updateUserSettings(settings) {
      const supabase = useSupabaseClient();
      const {error} = await supabase.from('user_settings').upsert(
        {
          document_processing_option: settings.document_processing_option,
          document_processing_lang: settings.document_processing_lang,
          notify_email_document_processed: settings.notify_email_document_processed,
          notify_product_updates: settings.notify_product_updates,
          user_id: this.user.id
        },
        {
          onConflict: 'user_id'
        }
      );
      if (error) throw error;
      this.userSettings = settings;
    },
    async signOut() {
      this.loading = true;
      const supabase = useSupabaseClient();
      const {error} = await supabase.auth.signOut();
      if (error) throw error;
      this.loading = false;
      this.isNewUser = false;
      return true;
    },
    async checkNewUser() {
      console.log('auth.store.checkNewUser', this.user);
      if (!this.user) return false;

      const createdAt = new Date(this.user.created_at);
      const now = new Date();
      this.isNewUser = now - createdAt < 1000 * 60 * 5;
      console.log('now', now);
      console.log('createdAt', createdAt);
      console.log('auth.store.checkNewUser', this.isNewUser);

      return this.isNewUser;
    },
    async initializeNewUser() {
      if (!this.user) return;

      const supabase = useSupabaseClient();

      const {data: existingSettings} = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', this.user.id)
        .single();

      if (!existingSettings) {
        await this.updateUserSettings({
          document_processing_option: 'all_pages',
          document_processing_lang: 'auto',
          notify_email_document_processed: true,
          notify_product_updates: true
        });
      }
    },
    reset() {
      this.user = null;
      this.userSettings = null;
      this.isLoading = false;
      this.isNewUser = false;
    }
  },
  persist: {
    paths: ['userSettings', 'isNewUser']
  }
});
